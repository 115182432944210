/**
 * components/_bands.scss
 */

/* ==========================================================================
   Bands
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

$band--primary__text-color: color(text, dark);
$band--primary__background-color: color(background, lightest);
$band--secondary__text-color: color(text, light);
$band--secondary__background-color: color(brand, primary);
$band--background-one__image: url(../img/homepage-asides__background.jpg);


/**
 * Full width, coloured wrappers. Designed to be used around .containers
 */


.band {
  margin: 0 0 6px;
  padding-top: $base-spacing-unit;
  padding-bottom: 1px;
  position: relative;
}

.band--primary {
  color: $band--primary__text-color;
  background-color: $band--primary__background-color;
}

.band--secondary {
  color: $band--secondary__text-color;
  background-color: $band--secondary__background-color;

  ::selection {
    background-color: color(text, dark);
    color: color(text, light);
  }

  ::-moz-selection {
    background-color: color(text, dark);
    color: color(text, light);
  }
}

.band--divide {
  border-bottom: 1px solid #282526;
}

/**
 * Backgrounds
 */

.band--background-one {
  @include media-query(desk) {
    background: $band--background-one__image center bottom;
    background-size: cover;
  }
}


/**
 * Modifiers
 */


@include media-query(lap-and-up) {
  .band--huge {
    padding-top: treble($base-spacing-unit);
    padding-bottom: double($base-spacing-unit);
  }

  .band--large {
    padding-top: double($base-spacing-unit);
    padding-bottom: $base-spacing-unit;
  }
}

.band--flush {
  padding-top: 0;
  padding-bottom: 0;
}

.band--flush-top {
  padding-top: 0;
}


.band--tes {
  @include flex-row;
  justify-content:center;
  align-items:center;
  flex-wrap:wrap;
  padding:12px;
  background:#182e43;
}
  .tes-logo {
    padding:12px;

    img {
      display:block;
      width:auto; max-height:64px;
    }
  }

  main {
    .band--tes {
      display: none;
    }
  }

@include media-query(desk) {
  main {
    .band--tes {
      display: flex;
    }
  }
}

/**
 * settings/_colors.scss
 */

/* ==========================================================================
   Color variable settings
   ========================================================================== */

/**
 * Color Palette
 */

$colors: (
  // Base text colors
  text: (
    dark:     #171717,
    medium:   #4a4a49,
    light:    #9b9b9a,
    lightest: #e9e9e9
  ),

  // Base background colors
  background: (
    darkest:  #000,
    dark:     #333,
    light:    #e9e9e9,
    lightest: #fff
  ),

  // Black and white
  bw: (
    black: #000,
    white: #fff
  ),

  // Brand colors
  brand: (
    primary:    #133370,
    secondary:  #756aa4,
    tertiary:   #e4e4f1,
    quaternary: #e9e9e9
  ),

  // Context states
  context: (
    info: #d9edf7,
    success: #dff0d8,
    warning: #fcf8e3,
    danger: #f2dedf
  ),

  // Social colors
  social: (
    facebook:    #365397,
    twitter:     #00a9f1,
    linkedin:    #006db3,
    apple:       #737373,
    google:      #4285f4,
    google-plus: #e0452c,
    youtube:     #f33,
    vimeo:       #48b6ed,
    pinterest:   #ce1a19,
    yelp:        #c30f00,
    dribbble:    #ed4584,
    amazon:      #ff9700,
    ebay:        #083790,
    skype:       #00acf4,
    instagram:   #396d9a,
    dropbox:     #0d84de,
    flickr:      #ea0066,
    github:      #2f2f2f,
    basecamp:    #6bbd6d,
    tumblr:      #304c68,
    foursquare:  #207dc5,
    box:         #1277bc
  )
);

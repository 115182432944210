/**
 * components/_nav.scss
 */

/* ==========================================================================
   Navigations
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

$nav--background-color: color(brand, primary);
$nav__item--border-color: transparentize(color(brand, tertiary), 0.8);
$nav-toggle--color: color(bw, white);


/**
 * Navigation
 */

.nav {
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  @include media-query(portable) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    pointer-events: none;

    &__list {
      position: absolute;
      top: 0;
      bottom: 0;
      left: -100%;
      width: 100%;
      max-width: 277px;
      background-color: $nav--background-color;
      transition: left double($base-anim-duration) $base-anim-easing;
    }

    &__item {
      border-bottom: 1px solid $nav__item--border-color;

      a {
        display: block;
        padding: $base-spacing-unit;
        color: color(text, lightest);
      }

      &--active > a {
        position: relative;

        &::before {
          position: absolute;
          top: 50%;
          left: 0;
          display: block;
          content: '';
          width: 6px;
          height: 42px;
          background-color: #5085e6;
          transform: translateY(-50%);
        }
      }
    }

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
      pointer-events: none;
      background: rgba(0, 0, 0, 0.5);
      visibility: hidden;
      opacity: 0;
      transition:
        visibility $base-anim-duration $base-anim-easing $base-anim-duration,
        opacity $base-anim-duration $base-anim-easing;
    }


    &--open {
      pointer-events: auto;

      &::before {
        visibility: visible;
        opacity: 1;
        transition-delay: 0s;
        pointer-events: auto;
      }

      .nav__list {
        left: 0;
      }
    }
  }

  @include media-query(desk) {
    display: flex;
    align-items: center;
    margin-left: $base-spacing-unit;


    &__list {
      display: flex;
      justify-content: flex-end;
    }

    &__item {
      margin: 0 0 0.5em $base-spacing-unit;
      display: inline-block;

      &:last-child {
        margin-right: 0;
      }

      a {
        display: block;
        padding-bottom: 4px;
        width: 100%;
        max-width: 120px;
        border-bottom: 2px solid #fff;
        color: color(bw, white);
        text-decoration: none;
        opacity: 0.6;
        transition: opacity $base-anim-duration $base-anim-easing;
      }

      &--active > a {
        opacity: 1;
      }
    }

    &__list:hover {
      .nav__item--active a {
        opacity: 0.6;
      }
      .nav__item--active:hover a,
      a:hover {
        opacity: 1;
      }
    }
  }
}


/**
 * Navigation Toggle Button
 */

.nav-toggle {
  position: fixed;
  // top: halve($base-spacing-unit);
  top: 1px;
  // right: halve($base-spacing-unit);
  right: 1px;
  z-index: 3;
  background-color: color(brand, secondary);

  @include media-query(lap) {
    // top: $base-spacing-unit;
    // right: $base-spacing-unit;
  }

  > span {
    display: block;
    padding: 19px 11px;
    // padding: 27px 19px;
    width: 18px;
    height: 2px;
    border: 0;
    box-sizing: content-box;
    background-clip: content-box;
    transition: background-color 500ms ease-in-out 250ms;
    background-color: $nav-toggle--color;

    &::before,
    &::after {
      position: relative;
      content: '';
      display: block;
      width: 18px;
      height: 2px;
      background-color: $nav-toggle--color;
      transition: transform 500ms ease-in-out;
    }

    &::before {
      top: -6px;
    }

    &::after {
      bottom: -4px;
    }
  }

  &__text {
    display: none;
  }

  &--active {
    > span {
      background-color: rgba(255, 255, 255, 0);
      transition-delay: 0s;

      &::before {
        transform: translateY(6px) rotate(-225deg);
      }

      &::after {
        transform: translateY(-6px) rotate(225deg);
      }
    }
  }
}


/**
 * Navigation Submenu
 */

.nav__item--has__submenu {
  @include flex-row;
  @include relative;
  align-items:center;
  flex-shrink:0;

  @media screen and (min-width:1024px) {

    &:after {
      @include absolute;
      content:"";
      left:0; right:0; bottom:-16px;
      height:16px;
    }

    &:hover > .subnav-primary-list,
    &:focus > .subnav-primary-list {
      opacity:1;
      visibility:visible;
      transform:translateY(0);
    }
  }
  @media screen and (max-width:1023px) {
    flex-wrap:wrap;
    justify-content:space-between;
  }

  & > a {

    @media screen and (min-width:1024px) {
      margin-right:9px;
    }
  }

  & > .subnav-primary-list {
    list-style:none;
    @media screen and (min-width:1024px) {
      top:35px; left:-19px;
      transform:translateY(6px);
    }
  }

  & > .subnav-primary-trigger svg {
    transform:rotate(-90deg);
  }
}

.subnav-primary-list {
  @include animate;

  @media screen and (min-width:1024px) {
    @include absolute(2);
    padding:24px;
    width:max-content;
    background:#182e43;
    opacity:0;
    visibility:hidden;
    transition:opacity 300ms ease-in-out, visibility 300ms ease-in-out, transform 300ms ease-in-out;
  }
  @media screen and (max-width:1023px) {
    display:none;
    padding:0 24px 24px;
    width:100%;
    box-sizing:border-box;
  }

  &.open {

    @media screen and (min-width:1024px) {
        opacity:1;
        visibility:visible;
        transform:translateY(0);
    }
    @media screen and (max-width:1023px) {
        display:block;
    }
  }
}
  .subnav-primary-item {

    &:not(:first-child) {
      padding-top:6px;

      @media screen and (max-width:1023px) {

        &:before {
          top:18px;
        }
      }
    }

    @media screen and (max-width:1023px) {
      @include relative;

      &:before {
        @include absolute;
        content:"";
        top:12px; left:-24px;
        width:6px; height:2px;
        background:#FFF;
      }
    }
  }
    .nav__item a.subnav-primary-link {
      padding:0;
      width:auto; max-width:none;
      border:none;

      &:not(.is-current) {
        opacity:.6 !important;
      }

      @media screen and (min-width:1024px) {
        &:hover,
        &:focus {
          opacity:1 !important;
        }
      }
    }

.subnav-primary-trigger {
  @include relative;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  appearance: none;

  &:before {
    @include animate;
    @include absolute(-1);
    content:"";
    top:50%; left:50%;
    width:48px; height:48px;
    border-radius:100%;
    transform:translate(-50%, -50%);

    @media screen and (max-width:1023px) {
        width:24px; height:24px;
    }
  }

  @media screen and (min-width:1024px) {
    &:hover,
    &:focus,
    &:active {

        svg {
          fill:#FFF;
        }

        & + .subnav-primary-list {
          opacity:1;
          visibility:visible;
          transform:translateY(0);
        }
    }
  }
  @media screen and (max-width:1023px) {
    margin-right:24px;
  }

  svg {
    @include animate;
    width:12px; height:12px;
    fill:rgba(#FFF, .6);
    pointer-events:none;
  }
}

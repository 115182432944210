/**
 * objects/_page.scss
 */

/* ==========================================================================
   Page content layout
   ========================================================================== */

/**
 * Layout the page with flexbox
 */

.page {
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
  flex-wrap: wrap;

  @include media-query(desk) {
    flex-direction: row;
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex: 2;
  }

  &__header {
    margin-bottom: 6px;
  }

  &__content {
    padding: $base-spacing-unit halve($base-spacing-unit);
    background-color: color(background, lightest);

    @include media-query(lap) {
      padding: $base-spacing-unit;
    }

    @include media-query(desk) {
      padding: $base-spacing-unit double($base-spacing-unit);
    }

    @include media-query(large-desk) {
      padding: $base-spacing-unit quintuple($base-spacing-unit);
    }
  }

  &__sidebar {
    flex: 1;

    @include media-query(desk) {
      margin-left: 9px;
    }
  }
}

/**
 * tools/_main.scss
 */

/* ==========================================================================
   Imports
   ========================================================================== */

@import 'colors';
@import 'font-size';
@import 'functions';
@import 'responsive';
@import 'vertical-align';

@mixin animate($speed: 300ms, $ease: ease-in-out) {
	transition-duration:$speed;
	transition-timing-function:$ease;
}

@mixin flex {
	display:flex;
	flex-wrap:nowrap;
}
@mixin flex-row {
	@include flex;
	flex-direction:row;
}
@mixin flex-column {
	@include flex;
	flex-direction:column;
}

@mixin relative($zindex: 1) {
	position:relative;
	z-index:$zindex;
}

@mixin absolute($zindex: 1) {
	position:absolute;
	z-index:$zindex;
}

@mixin full-size($zindex: 1) {
	position:absolute;
	top:0; left:0; right:0; bottom:0;
	z-index:$zindex;
}

@mixin aspect-ratio($aspect-ratio) {
    content:"";
    display:block;
    padding-top:calc((#{$aspect-ratio}) * 100%);
}

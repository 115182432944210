/**
 * settings/_base.scss
 */

/* ==========================================================================
   Base variable settings
   ========================================================================== */

/**
 * High level base settings
 */

$base-font-size:              16px;
$base-line-height:            24px;
$base-spacing-unit:           24px;
$base-container-width:	      1220px;
$base-container-gutter:       $base-spacing-unit;
$base-font:                   'Roboto', 'sans-serif';
$base-anim-duration:          300ms;
$base-anim-easing:            cubic-bezier(0.16, 0.68, 0.43, 0.99);
$base-anim-easing-alt:        cubic-bezier(0.175, 0.885, 0.32, 1.275);



/**
 * Typography
 */

$font-primary:   $base-font;
$font-secondary: 'Gobold', 'sans-serif';

@font-face {
  font-family: 'Gobold';
  src: url('../fonts/gobold_regular-webfont.eot');
  src:
    url('../fonts/gobold_regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/gobold_regular-webfont.woff2') format('woff2'),
    url('../fonts/gobold_regular-webfont.woff') format('woff'),
    url('../fonts/gobold_regular-webfont.ttf') format('truetype'),
    url('../fonts/gobold_regular-webfont.svg#goboldregular') format('svg');
  font-weight: normal;
  font-style: nomail;
}

@font-face {
  font-family: 'Gobold';
  src: url('../fonts/gobold_italic-webfont.eot');
  src:
    url('../fonts/gobold_italic-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/gobold_italic-webfont.woff2') format('woff2'),
    url('../fonts/gobold_italic-webfont.woff') format('woff'),
    url('../fonts/gobold_italic-webfont.ttf') format('truetype'),
    url('../fonts/gobold_italic-webfont.svg#gobold_italicitalic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family:'Roboto';
  font-style:normal;
  font-weight:300;
  font-display:swap;
  src: url('../fonts/roboto-300.woff2') format('woff2');
}

@font-face {
  font-family:'Roboto';
  font-style:normal;
  font-weight:400;
  font-display:swap;
  src: url('../fonts/roboto-400.woff2') format('woff2');
}

@font-face {
  font-family:'Roboto';
  font-style:normal;
  font-weight:500;
  font-display:swap;
  src: url('../fonts/roboto-500.woff2') format('woff2');
}

@font-face {
  font-family:'Roboto';
  font-style:normal;
  font-weight:700;
  font-display:swap;
  src: url('../fonts/roboto-700.woff2') format('woff2');
}

/**
 * base/_page.scss
 */

/* ==========================================================================
   Page
   ========================================================================== */

/**
 * Enabled the `border-box` box modle on all elements
 */

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Set font defaults, base colours and high-level page stylings
 */

html {
  font: 300 #{($base-font-size/16px) * 1em}/#{$base-line-height/$base-font-size} $base-font;
  color: color(text-color);
  background-color: color(background, light);
  overflow-x: hidden;
  overflow-y: scroll;
  min-height: 100%;
  max-width: 100%;
  position: relative;
}

body {
  margin: 0 auto;
  max-width: 1600px;

  @include media-query(desk) {
    padding: quarter($base-spacing-unit);
  }
}


/**
 * Set base branded user interactions
 */

::-moz-selection {
  background-color: color(brand, primary);
  color: #fff;
  text-shadow: none;
}

::selection {
  background-color: color(brand, primary);
  color: #fff;
  text-shadow: none;
}

:focus {
  outline: color(brand, primary) auto 5px;
}

/**
 * base/_images.scss
 */

/* ==========================================================================
   Images
   ========================================================================== */

/**
 * Easy fluid images
 */

img {
  max-width: 100%;
  height: auto;
  font-style: italic;
  vertical-align: middle;
}


/**
 * Fixes an issue with Google Maps
 */

.gm-style img {
  max-width: none;
}

/**
 * base/_paragraphs.scss
 */

/* ==========================================================================
   Paragraphs
   ========================================================================== */

strong {
  font-weight: bold;
}


/**
 * The `.lede` class is used to make the introductory text (usually a paragraph)
 * of a document slightly larger: en.wikipedia.org/wiki/Lede_(news)
 */

%lede,
.lede {
  @include font-size(24px, 1.5);

  &--accent {
    color: color(brand, primary);
  }

  &--nudge {
    margin-top: 0.25em;
  }
}

/**
 * objects/_container.scss
 */

/* ==========================================================================
   Container
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

$container__width: $base-container-width;


/**
 * Main container
 */

.container {
  margin-top: 0;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: $base-spacing-unit;
  padding-right: halve($base-spacing-unit);
  padding-left: halve($base-spacing-unit);
  width: 100%;
  max-width: $container__width;

  @include media-query(lap-and-up) {
    padding-right: $base-spacing-unit;
    padding-left: $base-spacing-unit;
  }
}


/**
 * Container modifiers
 */

.container--flush {
  margin-bottom: 0;
}

/**
 * components/_header.scss
 */

/* ==========================================================================
   Header
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

$header__height: 570px;
$header__height--sml: 360px;
$header__background-image: url(../img/header__background.jpg);


/**
 * Header styles
 */

.header {
  position: relative;
  background-image: $header__background-image;
  background-position: center;
  background-size: cover;

  &__container {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    margin: 0 0 6px;
    padding: double($base-spacing-unit) 0 treble($base-spacing-unit);

    @include media-query(portable) {
        z-index: 4;
      padding: $base-spacing-unit 0 double($base-spacing-unit);

      .logo {
        margin-right: (treble($base-spacing-unit) + 12);
      }
    }

    .header--home & {
      height: $header__height;

      @include media-query(portable) {
        height: $header__height--sml;
      }
    }
  }

  &__nav {
    @include relative(3);
    display: flex;
    justify-content: space-between;
    align-items: middle;
  }


  &__cta-btns {
    margin-bottom: 6px;

    @include media-query(lap-and-up) {
      position: absolute;
      z-index: 2;
      transform: translateY(-50%);
    }

    @include media-query(palm) {
      margin-left: (- halve($base-spacing-unit));
      margin-right: (- halve($base-spacing-unit));

      .btn {
        width: 100%;
      }
    }
  }
}

.header--home .header__cta-btns {
  position: relative;
  text-align: center;
  margin-top: 2rem;
  @include media-query(desk) {
    margin-top: -2rem;
  }
}


.hs-bg-video {
  @include full-size;
  overflow:hidden;
  background:#000;

  .wrapper-video {
    @include absolute;
    top:50%; left:50%;
    width:100%;
    transform:translate(-50%, -50%);

    iframe {
      // top: 50%; left: 50%; right: auto; bottom: auto;
      // width: 84vw; height: 84vh;
    }
  }
}

.header__slider {
    position:absolute;
    top:0; left:0; right:0; bottom:0;
    z-index:1;
    width: 100%; height:100%;

    &:before {
        content:"";
        position:absolute;
        top:0; left:0; right:0; bottom:0;
        z-index:102;
        width: 100%; height:100%;
        background-color: color(brand, primary);
        opacity:.24;
    }
    &:after {
        content:"";
        position:absolute;
        top:0; left:0; right:0; bottom:0;
        z-index:101;
        width: 100%; height:100%;
        background-color: #000;
        opacity:.48;
    }
}
    .header__slider__slide {
        top:0; left:0; right:0; bottom:0;
        width: 100%; height:100%;
        background-position: center;
        background-size: cover;

        // .wrapper-video {
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   right: 0;
        //   bottom: 0;
        //   z-index: 1;
        //   width: 100%;
        //   height: 100%;
        //   background-color: #000;
        // }
    }

.header__slider_pager {
    display:flex;
    flex-direction:row;
    position:absolute;
    right:24px; bottom:24px;
    z-index:3;

    span {
        display:block;
        width:24px; height:6px;
        background:rgba(#FFF, .32);
        font-size:0;
        text-indent:999em;
        cursor:pointer;
        overflow:hidden;

        &:not(:last-child) {
            margin-right:6px;
        }

        &.cycle-pager-active {
            background:#FFF;
            cursor:default;
        }
    }
}


/*
 * Sticky Header
 */

.header__nav--sticky {
  @include animate;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 12px 48px;
  background: rgba(#182e43, .96);
  transform: translateY(-100%);

  @media screen and (max-width:1023px) {
    display:none !important;
  }
}

body.is-sticky-header {

  .header__nav--sticky {
    transform: translateY(0);
  }
}


/*
 * Search
 */

.header-search_trigger-wrapper {
  @include relative;
  margin-left:24px;
  transform:translateY(-6px);

  @media screen and (min-width:769px) {
    &:hover,
    &:focus {

      .header-search_trigger svg {
        fill:#FFF;
      }

      .header-search {
        opacity:1;
        visibility:visible;
        transform:translateY(0);
      }
    }
  }

  &.v-desktop {
    @media screen and (max-width:1023px) {
      display:none !important;
    }
  }

  &.v-mobile {
    display:none !important;

    @media screen and (max-width:1023px) {
      display:block !important;
      position:fixed;
      top:1px; right:42px;
      z-index:5;
      margin:0;
      transform:none;
    }
  }
}
  .header-search_trigger {
    @include flex-column;
    justify-content:center;
    align-items:center;
    margin:0;
    padding:0;
    width:24px; height:24px;
    background:none;
    border:none;
    appearance:none;

    @media screen and (max-width:1023px) {
      width:40px; height:40px;
      background:color(brand, secondary);
    }

    svg {
      @include animate;
      display:block;
      width:16px; height:16px;
      fill:rgba(#FFF, .6);
      pointer-events:none;

      @media screen and (max-width:1023px) {
        fill:#FFF;
      }
    }
  }

.header-search {
  @include flex-row;
  @include absolute(2);
  top:35px; right:-12px;
  width:300px;
  background:#FFF;
  box-shadow:0 3px 48px 0 rgba(#000, .12);
  box-sizing:border-box;
  opacity:0;
  visibility:hidden;
  transition:opacity 300ms ease-in-out, visibility 300ms ease-in-out, transform 300ms ease-in-out;

  @media screen and (min-width:1024px) {
    transform:translateY(6px);
  }
  @media screen and (max-width:1023px) {
    top:0; right:41px;
    width:calc(100vw - 84px);
  }

  &:before {

    @media screen and (min-width:1024px) {
      @include absolute;
      content:"";
      top:-10px; left:0; right:0;
      height:10px;
    }
  }

  &.is-open {
    opacity:1;
    visibility:visible;
    transform:translateY(0);
  }

  .input-text {
    flex:1 1 auto;

    input {
      display:block;
      margin:0;
      padding:12px 18px;
      width:100%;
      line-height:1;
      font-size:16px;
      background:none;
      border:none;
      appearance:none;

      @media screen and (max-width:1023px) {
        padding:10px 18px 11px;
      }
    }
  }

  .header-search_btn {
    @include flex-column;
    justify-content:center;
    align-items:center;
    margin:0;
    padding:0;
    width:43px; height:43px;
    background:none;
    border:none;
    appearance:none;

    @media screen and (max-width:1023px) {
      width:40px; height:40px;
    }

    svg {
      @include animate;
      display:block;
      width:16px; height:16px;
      fill:color(brand, primary);
      pointer-events:none;
    }
  }
}

/**
 * components/_hero.scss
 */

/* ==========================================================================
   Hero
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

$hero__text-color: color(text, lightest);
$hero__text-color-alt: color(text, light);



/**
 * Hero styles
 */

.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  z-index: 1;

  &__title {
    @include font-size(48px);
    flex: 1;
    color: $hero__text-color-alt;
    line-height: 2;

    &--larger {
      @include font-size(64px);
    }

    @include media-query(portable) {
      @include font-size(35px);

      &--larger {
        @include font-size(45px);
      }
    }

    @include media-query(palm) {
      @include font-size(20px);

      &--larger {
        @include font-size(28px);
      }
    }
  }

  span {
    display: block;
    color: $hero__text-color;
    font-family: $font-secondary;
  }
}

.hero__slider__slide {
    top: 50% !important; left: 50% !important;
    margin: 0 !important;
    width:100%;
    transform: translate(-50%, -50%);
}
